<template>
  <div  class="mainwrap contlist" v-loading="loading">
        <div class="title">
            會員消費記錄查詢
        </div>

        <div class="searchform">
            <el-form
                label-width="100px"
                :inline="true"
                ref="searchForm"
                :model="searchForm"
                :rules="rules"
            >
                
                <div class="formwarp">
                    <div class="rowtitle">
                        <span class="text">會員資料</span>
                    </div>
                    <el-row>
                        <el-col :xl="6" :lg="10">
                        <el-form-item label="手機號碼：">
                            <el-input v-model="searchForm.Mobile" placeholder="請輸入手機號碼"></el-input>
                        </el-form-item>
                        </el-col>
                        <el-col :xl="6" :lg="14">
                        <el-form-item label="姓名：">
                            <el-input class="w_50" v-model="searchForm.LastName" placeholder="請輸入姓"></el-input>
                            <el-input class="w_50" v-model="searchForm.Name" placeholder="請輸入名"></el-input>
                        </el-form-item>
                        </el-col>
                        <el-col :xl="6" :lg="10">
                        <el-form-item label="性別：">
                            <el-select v-model="searchForm.Sex" placeholder="请选择">
                                <el-option label="请选择" value=""></el-option>
                                <el-option label="不願透露" value="0"></el-option>
                                <el-option label="男生" value="1"></el-option>
                                <el-option label="女生" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                        <el-col :xl="12" :lg="14">
                        <el-form-item label="生日：" prop="Birthday">
                            <el-date-picker
                                v-model="searchForm.BirthdayStart"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :default-value="defaultDateValue1"
                                :picker-options="pickerOptions1"
                                placeholder="選擇日期">
                            </el-date-picker>
                            -
                            <el-date-picker
                                v-model="searchForm.BirthdayEnd"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :default-value="defaultDateValue2"
                                :picker-options="pickerOptions2"
                                placeholder="選擇日期">
                            </el-date-picker>
                        </el-form-item>
                        </el-col>
                        <el-col :xl="10" :lg="24">
                        <el-form-item label="居住地區：">
                            <el-select v-model="searchForm.City" placeholder="请选择" @change="changeRegion">
                                <el-option label="請選擇" value=""></el-option>
                                <el-option v-for="item in parentRegionList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                            </el-select>
                            <el-select v-model="searchForm.Region" placeholder="请选择">
                                <el-option label="請選擇" value=""></el-option>
                                <el-option v-for="item in subRegionList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                    </el-row>
                     <el-collapse-transition>
                        <div v-show="showMore">
                            <div class="rowtitle">
                                <span class="text">POS消費記錄</span>
                            </div>
                            <el-row>
                                <el-col :xl="12" :lg="12">
                                    <el-form-item label="消費品牌別：" class="shopwrap">
                                        <!-- <el-input v-model="searchForm.BrandText" disabled placeholder="請選擇"></el-input>
                                        <el-button type="primary" @click="openShop2">選擇</el-button> -->
                                      <el-select v-model="searchForm.BrandText" placeholder="请选择" @change="getxinDianshop">
                                          <el-option label="請選擇" value=""></el-option>
                                          <el-option v-for="item in allBrandList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                                      </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :xl="12" :lg="12">
                                    <el-form-item label="消費店別：" class="shopwrap">
                                        <el-input v-model="searchForm.CounterText" disabled placeholder="請選擇"></el-input>
                                        <el-button type="primary" :disabled="isMore" @click="openShop">選擇</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :xl="12" :lg="12">
                                    <el-form-item label="消費金額類型：" label-width="110px" class="shopwrap">
                                        <el-select v-model="searchForm.PaymentAmountType" placeholder="请选择">
                                          <el-option v-for="item in typeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                                      </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :xl="12" :lg="12" class="numwrap">
                                    <el-form-item label="消費次數：" prop="PaymentNum">
                                            <el-input v-model="searchForm.PaymentNumStart" placeholder="請輸入"></el-input>
                                            -
                                            <el-input v-model="searchForm.PaymentNumEnd" placeholder="請輸入"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :xl="24" :lg="12" class="numwrap">
                                    <el-form-item label="消費金額：" prop="PaymentAmount">
                                            <el-input v-model="searchForm.PaymentAmountStart" placeholder="請輸入"></el-input>
                                            -
                                            <el-input v-model="searchForm.PaymentAmountEnd" placeholder="請輸入"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :xl="12" :lg="14">
                                    <el-form-item label="消費日期：" prop="PaymentTime">
                                        <el-date-picker
                                            v-model="searchForm.PaymentTimeStart"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            :default-value="defaultDateValue3"
                                            :picker-options="pickerOptions3"
                                            placeholder="選擇日期">
                                        </el-date-picker>
                                        -
                                        <el-date-picker
                                            v-model="searchForm.PaymentTimeEnd"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            :default-value="defaultDateValue4"
                                            :picker-options="pickerOptions4"
                                            placeholder="選擇日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                     </el-collapse-transition>

                    <div class="more" @click="handelMore">
                        <span>
                            <i :class="[showMore ? 'el-icon-caret-top':'el-icon-caret-bottom']"></i>
                        </span>
                    </div>
                </div>

                <el-col :span="12">
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查詢</el-button>
                    <el-popconfirm class="downLoad-popconfirm" confirm-button-text='下載名單' cancel-button-text='下載明細' icon="''" title="" @confirm="downLoad(1)" @cancel="downLoad(2)" >
                        <el-button type="success" slot="reference" :disabled="disabledDown">下載</el-button>
                    </el-popconfirm>
                    <!-- <el-button type="success" @click="downLoad" :disabled="disabledDown">下載</el-button> -->
                    <el-button @click="reSet">重置</el-button>
                </el-form-item>
                </el-col>
            </el-form>
        </div>

        <div class="tablewrap">
            <el-table :data="tableData" style="width: 100%" stripe>
                <el-table-column prop="mobile" label="手機號碼"> </el-table-column>
                <el-table-column prop="name" label="姓名"> </el-table-column>
                <el-table-column prop="birthday" label="生日"> </el-table-column>
                <el-table-column prop="sex" label="性別"> </el-table-column>
                <el-table-column prop="region" label="居住地區域城市"> </el-table-column>
                <el-table-column label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button @click="handleDest(scope.row)" type="primary" plain>
                        消費明細
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination">
            <el-pagination
                background
                :hide-on-single-page="isSinglePage"
                layout="prev, pager, next,jumper"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="currentPage"
                :total="tableDataTotal"
            >
            </el-pagination>
        </div>

        <el-dialog title="選擇店別" @close="cancelSelection" :visible.sync="shopVisible" width="40%">
            <div class="searchBox">
                <el-input v-model="shopWord"></el-input>
                <el-button type="primary" @click="searchShop">搜索</el-button>
            </div>
            <el-table :data="shopData" @selection-change="handleSelectionChange" row-key="key" ref="multipleTable">
                <el-table-column type="selection" width="100" :reserve-selection="true"></el-table-column>
                <el-table-column prop="value" label="名稱"></el-table-column>
            </el-table>
            <div class="bottom">
                <div style="margin-top: 20px">
                    <el-button type="primary" @click="applySelection">確定</el-button>
                    <el-button @click="cancelSelection">取消</el-button>
                </div>
                <div class="pagination">
                    <el-pagination
                    background
                    :hide-on-single-page="isSinglePage"
                    layout="prev, pager, next,jumper"
                    @current-change="handleCurrentChange2"
                    :page-size="pageSize2"
                    :current-page="currentPage2"
                    :total="tableDataTotal2"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
        <!-- <el-dialog title="選擇品牌別" :visible.sync="brandVisible" width="40%">
            <div class="searchBox">
                <el-input v-model="shopWord2"></el-input>
                <el-button type="primary" @click="searchBrand">搜索</el-button>
            </div>
            <el-table :data="brandData" @selection-change="handleSelectionChange2" row-key="key" ref="multipleTable2">
                <el-table-column type="selection" width="100" :reserve-selection="true"></el-table-column>
                <el-table-column prop="value" label="名稱"></el-table-column>
            </el-table>
            <div class="bottom">
                <div style="margin-top: 20px">
                    <el-button type="primary" @click="applySelection2">確定</el-button>
                    <el-button @click="cancelSelection2">取消</el-button>
                </div>
                <div class="pagination">
                    <el-pagination
                    background
                    :hide-on-single-page="isSinglePage"
                    layout="prev, pager, next,jumper"
                    @current-change="handleCurrentChange3"
                    :page-size="pageSize3"
                    :current-page="currentPage3"
                    :total="tableDataTotal3"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-dialog> -->
  </div>
</template>

<script>
import * as api from "./api";
import QS from "qs";
import {tableDataPagination} from "@/utils/common.js";
export default {
    name:"consumptionLog",
    data(){
        var validateRegion=(rule, value, callback) => {
            if(this.searchForm.parentRegion && !value){
                callback(new Error('请選擇居住區域'));
            }else{
                callback();
            }
        }
        var validatePaymentNum=(rule, value, callback) => {
            let reg = new RegExp("^[1-9]*[1-9][0-9]*$");
            if(this.searchForm.PaymentNumStart!=="" && !reg.test(this.searchForm.PaymentNumStart)){
                callback(new Error('请輸入正整數'));
            }
            if(this.searchForm.PaymentNumEnd!=="" && !reg.test(this.searchForm.PaymentNumEnd)){
                callback(new Error('请輸入正整數'));
            }
            if(this.searchForm.PaymentNumStart!=="" && this.searchForm.PaymentNumEnd!=="" && Number(this.searchForm.PaymentNumStart) > Number(this.searchForm.PaymentNumEnd)){
                callback(new Error('请輸入正確的區間'));
            }
            else{
                callback();
            }
        }
        var validateBirthday=(rule, value, callback) => {
            if(this.searchForm.BirthdayStart && !this.searchForm.BirthdayEnd){
                callback(new Error('请選擇開始/結束日期'));
            }else if(!this.searchForm.BirthdayStart && this.searchForm.BirthdayEnd){
                callback(new Error('请選擇開始/結束日期'));
            }
            else{
                callback();
            }
        }
        var validatePaymentTime=(rule, value, callback) =>{
            if(this.searchForm.PaymentTimeStart && !this.searchForm.PaymentTimeEnd){
                callback(new Error('请選擇開始/結束日期'));
            }else if(!this.searchForm.PaymentTimeStart && this.searchForm.PaymentTimeEnd){
                callback(new Error('请選擇開始/結束日期'));
            }
            else{
                callback();
            }
        }
        var validatePaymentAmount=(rule, value, callback)=>{
            if(this.searchForm.PaymentAmountStart!=="" && this.searchForm.PaymentAmountEnd!=="" 
                && Number(this.searchForm.PaymentAmountStart)>Number(this.searchForm.PaymentAmountEnd)){
                callback(new Error('请輸入正確的區間'));
            }
            else{
                callback();
            }
        }
        return{
            loading:false,
            activeTabName:"userinfo",
            disabledDown:true,
            searchForm:{
                Mobile:"",
                Name:"",
                LastName:"",
                BirthdayStart:"",
                BirthdayEnd:"",
                Sex:"",
                City:"",
                Region:"",
                CounterText:"",
                BrandText:"",
                PaymentTimeStart:"",
                PaymentTimeEnd:"",
                PaymentNumStart:"",
                PaymentNumEnd:"",
                PaymentAmountStart:"",
                PaymentAmountEnd:"",
                PaymentAmountType:''
            },
            rules:{
                Region: [
                    { validator: validateRegion, trigger: 'change' }
                ],
                PaymentNum:[
                    { validator: validatePaymentNum, trigger: 'change' }
                ],
                Birthday:[
                    { validator: validateBirthday, trigger: 'change' }
                ],
                PaymentTime:[
                    { validator: validatePaymentTime, trigger: 'change' }
                ],
                PaymentAmount:[
                    { validator: validatePaymentAmount, trigger: 'change' }
                ]
            },
            pickerOptions1:{
                disabledDate:this.dealDisabledDate1,
            },
            pickerOptions2:{
                disabledDate:this.dealDisabledDate2,
            },
            pickerOptions3:{
                disabledDate:this.dealDisabledDate3,
            },
            pickerOptions4:{
                disabledDate:this.dealDisabledDate4,
            },
            defaultDateValue1:"",
            defaultDateValue2:"",
            defaultDateValue3:"",
            defaultDateValue4:"",
            tableData:[],
            isSinglePage:false,
            currentPage:1,
            pageSize:10,
            tableDataTotal:0,
            shopVisible:false,
            brandVisible:false,
            shopData:[],
            brandData:[],
            brandId:'',
            currentPage2:1,
            currentPage3:1,
            pageSize2:10,
            pageSize3:10,
            tableDataTotal2:0,
            tableDataTotal3:0,
            allRestauranList:[],
            allBrandList:[],
            shopWord:"",
            shopWord2:"",
            multipleSelection:[],
            multipleSelection2:[],
            counterIdArr:[],
            brandIdArr:[],
            parentRegionList:[],
            subRegionList:[],
            showMore:false,
            isMore:false,
            typeList: [{
              key:0,
              value:'單筆'
            },{
              key:1,
              value:'匯總'
            }]
        }
    },
    created(){
        this.getShopData();
        this.getBranchData();
        this.getRegionData();
        // this.getList();
    },
    methods:{
        // 獲取選擇列表
        getShopData(Brand=""){
            let params={};
            if(Brand){
                params.BrandId=Brand;
            }
            this.allRestauranList = []
            this.isMore = true
            this.shopVisible=false
            api.getCounterList(params).then(response=>{
                if(response.systemCode===200){
                    this.allRestauranList=response.data;
                }
                this.isMore = false
            })
        },
        getBranchData(){
            api.getBrandBieList().then(response=>{
                if(response.systemCode===200){
                    this.allBrandList=response.data;
                }
            })
        },
        // 獲取區域
        getRegionData(parentId=""){
            let params={};
            if(parentId){
                params.parentId=parentId;
            }
            api.getRegionList(params).then(response=>{
                if(response.systemCode===200){
                    if(!parentId){
                        this.parentRegionList=response.data;
                    }else{
                        this.subRegionList=response.data;
                    }
                }
            })
        },
        changeRegion(val){
            this.searchForm.Region="";
            if(val){
                this.getRegionData(val);
            }
        },
        // 獲取列表
        getList(){
            let params={
                BirthdayEnd:this.searchForm.BirthdayEnd ? this.searchForm.BirthdayEnd + " " + "23:59:59" : "",
                BirthdayStart: this.searchForm.BirthdayStart ? this.searchForm.BirthdayStart + " " + "00:00:00" : "",
                CounterId: this.searchForm.CounterText? this.counterIdArr : "",
                BrandId: this.searchForm.BrandText,
                PaymentAmountType: this.searchForm.PaymentAmountType,
                Mobile: this.searchForm.Mobile,
                Name: this.searchForm.Name,
                LastName: this.searchForm.LastName,
                PaymentAmountEnd: this.searchForm.PaymentAmountEnd,
                PaymentAmountStart: this.searchForm.PaymentAmountStart,
                PaymentNumEnd: this.searchForm.PaymentNumEnd,
                PaymentNumStart: this.searchForm.PaymentNumStart === "" ? 1 : this.searchForm.PaymentNumStart,
                PaymentTimeEnd: this.searchForm.PaymentTimeEnd ? this.searchForm.PaymentTimeEnd + " " + "23:59:59" : "",
                PaymentTimeStart: this.searchForm.PaymentTimeStart ? this.searchForm.PaymentTimeStart + " " + "00:00:00" : "",
                City: this.searchForm.City,
                Region: this.searchForm.Region,
                Sex: this.searchForm.Sex,
                SkipCount:(this.currentPage-1)*this.pageSize,
                MaxResultCount:this.pageSize
            }
            // 拼接參數
            let paramsStr="";
            for (let key in params){
                if(params[key]!=="" && key!=="CounterId"){
                    paramsStr+=`${key}=${params[key]}&`;
                }
            }
            // 拼接CounterId:[1,2] => CounterId=1&CounterId=2
            if(params.CounterId!==""){
                paramsStr+=QS.stringify({CounterId: params.CounterId}, { arrayFormat: 'repeat' })
            }else{
                paramsStr=paramsStr.slice(0,-1);
            }
            this.loading=true;
            this.disabledDown=true;
            api.getPaymentMemberList(paramsStr).then(res=>{
                if(res.systemCode===200){
                    this.tableData=res.data.items;
                    this.tableDataTotal=res.data.totalCount;
                    this.disabledDown=false;
                }
                this.loading=false;
            })
        },
        // 處理表格數據
        getPageRes (){
            let res= tableDataPagination(this.allRestauranList,this.currentPage2,this.pageSize2,this.shopWord);
            console.log(res);
            this.shopData=res.dataList;
            this.tableDataTotal2=res.dataTotal;
        },
        // 處理品牌別數據
        getPageRes2(){
            let res=tableDataPagination(this.allBrandList,this.currentPage3,this.pageSize3,this.shopWord2);
            this.brandData=res.dataList;
            this.tableDataTotal3=res.dataTotal;
        },
        // 計算禁用日期
        dealDisabledDate1(time){
            if(this.searchForm.BirthdayEnd){
                this.defaultDateValue1=this.searchForm.BirthdayEnd;
                return  time.getTime() > new Date(this.searchForm.BirthdayEnd);
            }else{
                this.defaultDateValue1="";
                return false
            }
        },
         // 計算禁用日期
        dealDisabledDate2(time){
            if(this.searchForm.BirthdayStart){
                this.defaultDateValue2=this.searchForm.BirthdayStart;
                return time.getTime() < new Date(this.searchForm.BirthdayStart) - 24 * 60 * 60 * 1000;
            }else{
                this.defaultDateValue2="";
                return false
            }
        },
        // 計算禁用日期
        dealDisabledDate3(time){
            if(this.searchForm.PaymentTimeEnd){
                this.defaultDateValue3=this.searchForm.PaymentTimeEnd;
                return  time.getTime() > new Date(this.searchForm.PaymentTimeEnd);
            }else{
                this.defaultDateValue3="";
                return false
            }
        },
         // 計算禁用日期
        dealDisabledDate4(time){
            if(this.searchForm.PaymentTimeStart){
                this.defaultDateValue4=this.searchForm.PaymentTimeStart;
                return time.getTime() < new Date(this.searchForm.PaymentTimeStart) - 24 * 60 * 60 * 1000 ;
            }else{
                this.defaultDateValue4="";
                return false
            }
        },
        // 打開彈窗
        openShop(){
            this.currentPage2=1;
            this.getPageRes();
            this.shopVisible=true;
            if(this.searchForm.CounterText==="" && this.$refs.multipleTable){
                this.$refs.multipleTable.clearSelection();
            }
        },
        // 打開品牌別彈窗
        openShop2(){
            this.getPageRes2();
            this.brandVisible=true;
            if(this.searchForm.BrandText==="" && this.$refs.multipleTable2){
                this.$refs.multipleTable2.clearSelection();
            }
        },
        // 查詢
        onSearch(){
            let reg = new RegExp("^[1-9]*[1-9][0-9]*$");
            // if(this.searchForm.City && !this.searchForm.Region){
            //     this.$refs.searchForm.validateField("Region");
            //     return;
            // }
            if(this.searchForm.PaymentNumStart!=="" && !reg.test(this.searchForm.PaymentNumStart)){
                this.$refs.searchForm.validateField("PaymentNum");
                return;
            }
            if(this.searchForm.PaymentNumEnd!=="" && !reg.test(this.searchForm.PaymentNumEnd)){
                this.$refs.searchForm.validateField("PaymentNum");
                return;
            }
            if(this.searchForm.PaymentNumStart!=="" && this.searchForm.PaymentNumEnd!=="" && 
                Number(this.searchForm.PaymentNumStart) > Number(this.searchForm.PaymentNumEnd)){
                this.$refs.searchForm.validateField("PaymentNum");
                return;
            }
            if(this.searchForm.BirthdayStart && !this.searchForm.BirthdayEnd){
                this.$refs.searchForm.validateField("Birthday");
                return;
            }
            if(!this.searchForm.BirthdayStart && this.searchForm.BirthdayEnd){
                this.$refs.searchForm.validateField("Birthday");
                return;
            }
            if(this.searchForm.PaymentTimeStart && !this.searchForm.PaymentTimeEnd){
                this.$refs.searchForm.validateField("PaymentTime");
                return;
            }else if(!this.searchForm.PaymentTimeStart && this.searchForm.PaymentTimeEnd){
                this.$refs.searchForm.validateField("PaymentTime");
                return;
            }
            if(this.searchForm.PaymentAmountStart!=="" && this.searchForm.PaymentAmountEnd!=="" 
                && Number(this.searchForm.PaymentAmountStart)>Number(this.searchForm.PaymentAmountEnd)){
                this.$refs.searchForm.validateField("PaymentAmount");
                return;
            }
            this.currentPage=1;
            this.getList();
        },
        // 下載
        downLoad(index){
            this.disabledDown=true;
            let params={
                BirthdayEnd:this.searchForm.BirthdayEnd ? this.searchForm.BirthdayEnd + " " + "23:59:59" : "",
                BirthdayStart: this.searchForm.BirthdayStart ? this.searchForm.BirthdayStart + " " + "00:00:00" : "",
                CounterId: this.searchForm.CounterText? this.counterIdArr : "",
                BrandId: String(this.searchForm.BrandText),
                Mobile: this.searchForm.Mobile,
                Name: this.searchForm.Name,
                PaymentAmountType: this.searchForm.PaymentAmountType,
                LastName: this.searchForm.LastName,
                PaymentAmountEnd: this.searchForm.PaymentAmountEnd,
                PaymentAmountStart: this.searchForm.PaymentAmountStart,
                PaymentNumEnd: this.searchForm.PaymentNumEnd,
                PaymentNumStart: this.searchForm.PaymentNumStart === "" ? 1 : this.searchForm.PaymentNumStart,
                PaymentTimeEnd: this.searchForm.PaymentTimeEnd ? this.searchForm.PaymentTimeEnd + " " + "23:59:59" : "",
                PaymentTimeStart: this.searchForm.PaymentTimeStart ? this.searchForm.PaymentTimeStart + " " + "00:00:00" : "",
                City: this.searchForm.City,
                Region: this.searchForm.Region,
                Sex: this.searchForm.Sex,
            }
            for (let key in params){
                if(params[key]===""){
                    delete params[key];
                }
            }
            this.loading=true;
            if(index == 1){
                api.exportPaymentMemberReport(params).then(res=>{
                    if(res.systemCode===200){
                        window.open(res.data,"_blank")
                    }
                    this.disabledDown=false;
                    this.loading=false;
                })
            }else{
                api.paymentExportReport(params).then(res=>{
                    if(res.systemCode===200){
                        window.open(res.data,"_blank")
                    }
                    this.disabledDown=false;
                    this.loading=false;
                })
            }
        },
        // 重置
        reSet(){
            this.searchForm={
                Mobile:"",
                Name:"",
                LastName:"",
                BirthdayStart:"",
                BirthdayEnd:"",
                Sex:"",
                City:"",
                Region:"",
                CounterText:"",
                BrandText:"",
                PaymentTimeStart:"",
                PaymentTimeEnd:"",
                PaymentNumStart:"",
                PaymentNumEnd:"",
                PaymentAmountStart:"",
                PaymentAmountEnd:"",
                PaymentAmountType: ''
            }
            this.subRegionList=[];
            this.counterIdArr=[];
            this.brandIdArr=[];
            this.currentPage=1;
            this.shopWord=''
            this.brandId='',
            // this.getList();
            this.getShopData();
        },
        // 查看明細
        handleDest(item){
            console.log(this.brandId);
            this.$router.push({
                path: "/cms/userconsumptionlog",
                query: {
                    id: item.id,
                    name:item.name,
                    CounterId: this.searchForm.CounterText? this.counterIdArr : "",
                    BrandId: this.brandId,
                    PaymentAmountEnd: this.searchForm.PaymentAmountEnd,
                    PaymentAmountStart: this.searchForm.PaymentAmountStart,
                    PaymentNumEnd: this.searchForm.PaymentNumEnd,
                    PaymentNumStart: this.searchForm.PaymentNumStart === "" ? 1 : this.searchForm.PaymentNumStart,
                    PaymentTimeEnd: this.searchForm.PaymentTimeEnd ? this.searchForm.PaymentTimeEnd + " " + "23:59:59" : "",
                    PaymentTimeStart: this.searchForm.PaymentTimeStart ? this.searchForm.PaymentTimeStart + " " + "00:00:00" : "",
                    PaymentAmountType: this.searchForm.PaymentAmountType,
                },
            });
        },
        // 翻頁
        handleCurrentChange(val) {
            this.currentPage=val;
            this.getList();
        },
        // 店翻頁
        handleCurrentChange2(val){
            this.currentPage2=val;
            this.getPageRes();
        },
        // 品牌翻頁
        handleCurrentChange3(val){
            this.currentPage3=val;
            this.getPageRes2();
        },
        // 搜索店
        searchShop(){
            this.currentPage2=1;
            this.getPageRes();
        },
        // 搜索品牌
        searchBrand(){
            this.currentPage3=1;
            this.getPageRes2();
        },
        // 多選
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 多選
        handleSelectionChange2(val) {
            this.multipleSelection2 = val;
        },
        applySelection(){
            this.shopVisible=false;
            if(this.multipleSelection.length>0){
                this.searchForm.CounterText="";
                this.counterIdArr=[];
                let ctStr="";
                this.multipleSelection.forEach(item=>{
                    ctStr+=item.value+"，"
                    this.counterIdArr.push(item.key)
                })
                this.searchForm.CounterText=ctStr.slice(0,-1);
            }else if(this.multipleSelection.length==0){
                this.searchForm.CounterText="";
                this.counterIdArr=[];
            }
        },
        applySelection2(){
            this.brandVisible=false;
            if(this.multipleSelection2.length>0){
                this.searchForm.BrandText="";
                this.brandIdArr=[];
                let ctStr="";
                this.multipleSelection2.forEach(item=>{
                    ctStr+=item.value+"，"
                    this.brandIdArr.push(item.key)
                })
                this.searchForm.BrandText=ctStr.slice(0,-1);
                this.searchForm.CounterText="";
                this.counterIdArr=[];
                console.log(this.brandIdArr);
                this.getShopData(this.brandIdArr)
            }else if(this.multipleSelection2.length==0){
                this.searchForm.BrandText="";
                this.brandIdArr=[];
                this.getShopData(this.brandId)
            }
        },
        getxinDianshop(e) {
          this.searchForm.CounterText="";
          this.counterIdArr=[];
          this.brandId = e
          console.log(this.brandId,e);
          this.getShopData(e)
        },
        cancelSelection(){
            this.shopVisible=false;
            this.shopWord = ''
        },
        cancelSelection2(){
            this.brandVisible=false;
        },
        handelMore(){
            this.showMore=!this.showMore;
        }
    }
}
</script>

<style lang="scss" scoped>
.contlist{
    .searchform {
        height: auto;
        padding: 20px 0px;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
        /deep/{
            .el-tabs__header{
                width: 205px;
            }
            .el-tabs__item{
                padding: 0 15px;
            }
        }
    }
    .w_50{
        width: 130px;
    }
    .shopwrap {
        width: 100%;
        .el-input {
            width: 70%;
            margin-right: 10px;
        }
        .el-form-item__content {
            width: calc(100% - 100px);
        }
    }
    .numwrap{
        .el-input {
            width: 40%;
        }
        /deep/ .el-form-item__content {
            width: calc(100% - 100px);
        }
    }
    .rowtitle{
        margin-bottom: 18px;
        height: 20px;
        .text{
            display: inline-block;
            // width: 120px;
            padding: 6px 15px;
            padding-bottom: 6px;
            text-align: center;
            border-bottom: 2px solid #409EFF;
        }
        
    }
    .searchBox{
        width: 500px;
        margin-bottom: 10px;
        .el-input {
            width: 250px;
            margin-right: 10px;
        }
    }
    .downLoad-popconfirm{
        display: inline-block;
        margin: 0 10px;
    }
    .formwarp{
        border: 1px solid #eaeefb;
        padding: 20px 20px 0px;
        margin-bottom: 15px;
    }
    .more{
        border-top: 1px solid #eaeefb;
        text-align: center;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        &:hover{
            color: #409eff;
            background-color: #f9fafc;
        }
    }
}
</style>